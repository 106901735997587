.draggable {
    display: flex;
    width: fit-content;
    background-color:red;
    border-style: solid;
    font-size: 32px;
}

.square {
    align-content: center;
    border-right: solid;
    cursor: move;
    width: 50px;
    height: 50px;
}

.square:last-child {
    border-right: none;
}

.no_scroll {
    overflow: hidden;
}